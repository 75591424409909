@font-face {
  font-family: 'RBNo21b-Bold';
  src:  url('../fonts/3334F6_0_0.woff2') format('woff2'),
        url('../fonts/3334F6_0_0.woff') format('woff'),
        url('../fonts/3334F6_0_0.ttf') format('truetype');
}

@lost flexbox flex;

@custom-media --tiny (width <= 400px);
@custom-media --small (width >= 601px);
@custom-media --medium (width >= 769px);
@custom-media --large (width >= 1200px);

:root {
  --red: rgba(156, 21, 25, 1);
  --green: rgba(138, 150, 56, 1);
  --lightGreen: rgba(138, 150, 56, 0.5);
  --black: rgba(0, 0, 0, 1);
  --darkGrey: rgba(0, 0, 0, 0.8);
  --midGrey: rgba(0, 0, 0, 0.5);
  --lightGrey: rgba(246, 245, 239, 1);
  --bodyFont: '"Helvetica Neue", Helvetica, Arial, sans-serif';
  --headingFont: 'RBNo21b-Bold';
}

body {
  background: rgba(255, 255, 255, 1);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--darkGrey);
  margin: initial;
  padding: initial;
}

h1, h2, h3, h4 {
  color: var(--red);
  font-family: var(--headingFont);
  font-weight: normal;
  margin: initial;
}

h1 {
  font-size: 2.4rem;
  line-height: 1.1em;
  margin-bottom: 1.5rem;
}

p {
  font-size: 1.14rem;
  margin: initial;
  margin-bottom: 1.5rem
}

blockquote {
  font-size: 1.28rem;
  font-weight: bold;
  color: var(--red);
  margin: initial;
}

a {
  color: var(--green);
}

header {
  background-color: var(--darkGrey);
}

#DunblaneMuseumLogo {
  fill: #ffffff;
  min-width: 30px;
  max-width: 3vmax;

  @media (--small) {
    margin-left: 18px;
  }
}

.primaryNav,
.subNav > ul,
.extraNav > ul {
  lost-center: 1200px;

  & div {
    margin-top: 9px;
    margin-bottom: 3px;
    display: block;
    width: 100%;
    text-align: center;

    @media (--small) {
      lost-column: 1/2;
      margin-top: 18px;
      margin-bottom: 9px;
      text-align: left;
    }

    & ul {
      text-align: center;
      padding: initial;

      @media (--small) {
        text-align: right;
        margin-right: 18px;
      }

      & li {
        display: inline-block;
        list-style-type: none;

        & a {
          border-bottom: 4px solid var(--green);
          text-decoration: none;
          padding: 9px 1px 0 1px;
          margin: 0 9px;
          cursor: pointer;
        }
      }
    }
  }
}

.primaryNav a {
  color: #fff;
  border-color: var(--green);
}

.subNav {
  background: var(--lightGrey);
  padding: 18px 0;

  & .container {
    lost-center: 1200px;

  }

  & ul {
    margin: 0 18px;
    padding: initial;

    @media (--medium) {
      lost-column: 1/2;
      padding: 0 18px;
    }

    & li {
      display: inline-block;
      list-style-type: none;

      & a {
        color: var(--midGrey);
        font-weight: bold;
        border-bottom: 4px solid var(--green);
        text-decoration: none;
        padding: 9px 0 0;
        margin: 0 9px 0 0;
        cursor: pointer;
        line-height: 1.5rem;
      }
    }
  }
}

.primaryBanner {
  background: url('../img/decoration/largeTexture.jpg') no-repeat center center;
  background-size: cover;
  display: block;
  min-height: 140px;
  position: relative;
  align-items: flex-end;
  display: flex;
  justify-content: center;

  @media (--small) {
    min-height: 229px;
  }

  & h1 {
    lost-center: 1200px;
    margin-bottom: 0;
    width: 100%;
    font-size: 2.2rem;
    color: #fff;
    position: absolute;
    bottom: 46px;
    left: 18px;
    letter-spacing: 0.03em;

    @media (--small) {
      font-size: 2.5rem;
      bottom: 63px;
    }

    & div {
      font-size: 3.7rem;
      text-transform: uppercase;
      position: absolute;
      bottom: -45px;
      left: 41px;

      @media (--small) {
        font-size: 5rem;
        bottom: -57px;
        left: 41px;
      }

      @media (--tiny) {
        left: 0;
        font-size: 3rem;
        bottom: -48px;
      }
    }
  }
}

.videoFeed {
  background: green;
  position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
}

.videoFeed iframe, .videoFeed object, .videoFeed embed {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
}

.bodySection {
  background: pink;

  & article {
    lost-center: 1200px;
    padding: 27px 18px;

    @media (--medium) {
      padding: 36px 18px;
    }

    @media (--large) {
      padding: 54px 0;
    }

    & h1 {
      display: block;
      padding-bottom: 19px;
      background: url('../img/decoration/headingTexture.jpg') no-repeat bottom;
    }

    & h2 {
      color: var(--darkGrey);
      margin-bottom: 1.6rem;
      font-size: 1.8rem;
    }

    & .bodySection--left {

      @media (--small) {
        lost-column: 3/4;
      }

      & p:first-of-type {
        font-weight: bold;
      }
    }

    & .bodySection--right {

      @media (--small) {
        lost-column: 3/4;
        lost-offset: 1/4;
      }

      & .profiles {
        display: flex;
        margin-top: 27px;
      }
    }
  }

  & .profile {
    lost-column: 1/2;
    box-sizing: border-box;
    /*lost-utility: edit;*/
    text-align: center;

    & img {
      margin-bottom: 18px;
      max-width: 100%;
    }
  }

  & figure {
    width: 100%;
    margin: initial;
    text-align: center;

    & img {
      max-width: 100%;
      padding: initial;
      vertical-align:text-bottom;
    }

    & figcaption {
      background: var(--darkGrey);
      lost-align: center;
      width: 100%;
      padding: 18px 0;
      font-size: 1.4rem;
      color: #fff;
    }
  }

  & ol {
    list-style: none;
    counter-reset: my-awesome-counter;

    & li {
      counter-increment: my-awesome-counter;
      margin: 0.5rem;
    }

    & li::before {
      content: counter(my-awesome-counter);
      background: var(--red);
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: inline-block;
      line-height: 2rem;
      color: white;
      text-align: center;
      margin-right: 0.5rem;
      font-family: var(--headingFont);
    }
  }
}

.resources {
  margin: initial;
  padding: initial;

  & li {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-right: 15px;
    margin-bottom: 27px;

    @media (--small) {
      width: 45.5%;
    }

    @media (--medium) {
      width: 46%;
    }

    @media (--large) {
      width: 47.5%;
    }

    & p {
      font-weight: normal!important;
    }

    & h3 {
      margin-bottom: 12px;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      letter-spacing: -0.03em;
      font-weight: bold;
      color: var(--darkGrey);
    }
  }
}

.resources--pack a,
.resources--boards a {
  color: #fff;
  padding: 9px 45px;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 6px;
  text-decoration: none;
}

.resources--pack {
  & a {
    background: var(--green);
  }
}

.resources--boards {
  & a {
    background: var(--red);
  }
}

.resources--acknowledged {
  & li {
    margin-bottom: 12px;
  }
}

.bodySection--white {
  background: #fff;
}

.bodySection--grey {
  background: var(--lightGrey);
}


main,
footer {
  lost-center: 1200px;
  lost-align: center;
}

.credits,
.extraNav,
.site-meta {
  & .container {
    lost-center: 1200px;
  }
  & a,
  & p {
    color: #fff;
    font-size: 1rem;
  }
}

.credits {
  background-color: var(--lightGrey);
  /*lost-align: center;*/
  padding: 27px 18px;

  @media (--large) {
    padding: 27px 0;
  }

  & h2 {
    lost-center: 1200px;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1rem;
    padding-bottom: 18px;
    margin-bottom: 27px;
    border-bottom: 1px solid var(--midGrey);
    display: block;
    color: var(--midGrey);
  }

  & img {
    max-width: 100%;
  }

  & .container {
    & div {
      lost-align: center;
      margin-bottom: 18px;

      & img {
        max-width: 80%;
      }
    }

    & .museum {
      lost-column: 1/1 1 0;

      @media (--small) {
        lost-column: 1/4 3 0;
      }
    }

    & .lottery {
      lost-column: 1/1 1 0;

      @media (--small) {
        lost-column: 1/2 3 0;
      }
    }

    & .covenant {
      lost-column: 1/1 1 0;

      @media (--small) {
        lost-column: 1/4 3 0;
      }
    }
  }

}

.extraNav {
  background-color: var(--midGrey);
  padding: 27px 0;

  & ul {
    padding: initial;
    margin: 0 18px;

    @media (--large) {
      margin: initial;
    }

    & li {
      list-style-type: none;

      & a {
        text-decoration: none;
        padding: initial;
        cursor: pointer;
      }
    }
  }
}

.site-meta {
  background-color: var(--darkGrey);
  padding: 18px;

  @media (--large) {
    padding: 18px 0;
  }

  & .container {

    & div {
      display: block;
      width: 100%;
      text-align: center;

      @media (--small) {
        lost-column: 1/2;
        text-align: left;
      }

      & p {
        line-height: 1.6rem;
      }

      & ul {
        text-align: center;
        padding: initial;

        @media (--small) {
          text-align: right;
        }

        & li {
          display: inline-block;
          list-style-type: none;

          & a {
            border-bottom: 4px solid var(--green);
            text-decoration: none;
            padding: 9px 1px 0 1px;
            margin: 0 9px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
